const video = () => {
    const items = document.querySelectorAll('.js-video');

    items.forEach(item => {
        const play = item.querySelector('.js-video__play');
        const placeholder = item.querySelector('.js-video__placeholder');

        if (play) {
            play.addEventListener('click', () => {
                item.insertAdjacentHTML('beforeend', `<video playsinline autoplay controls src="${play.dataset.videoSrc}"></video>`)
                play.remove();
                placeholder.remove();
            });
        }
    });
};

export default video;