const header = () => {
    const header = document.querySelector('.js-header');

    if (header.classList.contains('js-header--show-on-scroll-up')) {
        let scrollPos = 0;

        global.addEventListener('scroll', () => {
            const { top } = document.body.getBoundingClientRect();
            const isSticky = header.classList.contains('position-sticky');

            const handleScrollTop = () => {
                header.classList.add('site-header--top');
                header.classList.remove('position-sticky');
                header.style.transform = '';
            };

            const handleScrollUp = () => {
                if (!isSticky) {
                    header.classList.add('position-sticky');
                    header.style.transition = 'none';
                    header.style.transform = 'translateY(-100%)';

                    global.setTimeout(() => {
                        header.style.transition = '';
                        header.style.transform = '';
                    });
                }
            };

            const handleScrollDown = () => {
                header.classList.remove('site-header--top');

                if (isSticky) {
                    global.setTimeout(() => {
                        header.style.transform = 'translateY(-100%)';

                        global.setTimeout(() => {
                            header.classList.remove('position-sticky');
                        }, 250);
                    });
                }
            };

            if (top === 0) {
                handleScrollTop();
            } else if (top > scrollPos) {
                handleScrollUp();
            } else {
                handleScrollDown();
            }

            scrollPos = top;
        });
    } else if (header.classList.contains('position-sticky')) {
        global.addEventListener('scroll', () => {
            const { top } = document.body.getBoundingClientRect();

            console.log(top);

            if (top === 0) {
                header.classList.add('site-header--top');
            } else {
                header.classList.remove('site-header--top');
            }
        });
    }
};

export default header;