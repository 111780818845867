const nav = () => {
    const navToggle = document.querySelector('.js-nav-toggle');

    if (navToggle) {
        const nav = document.getElementById(navToggle.getAttribute('aria-controls'));

        const openNav = () => {
            navToggle.classList.add('is-open');
            nav.classList.add('is-open');
            nav.ariaHidden = false;
            navToggle.ariaExpanded = true;
            document.documentElement.classList.add('has-open-nav');
        };

        const closeNav = () => {
            navToggle.classList.remove('is-open');
            nav.classList.remove('is-open');
            nav.ariaHidden = true;
            navToggle.ariaExpanded = false;
            document.documentElement.classList.remove('has-open-nav');
        };

        navToggle.addEventListener('click', () => {
            if (navToggle.ariaExpanded === 'true') {
                closeNav();
            } else {
                openNav();
            }
        });
    }
};

export default nav;